<script>

export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data() {
    const lowResolutionImage = this.link.image?.id
      && !this.link.image.dimension.isEmpty
      && this.$toLitiumMediaUrl(this.link.image.id, { maxWidth: 50 });

    return {
      highResolutionImage: null,
      imgUrl: lowResolutionImage,
      isImageDownloaded: false,
    };
  },
  mounted() {
    if (!this.imgUrl) return;

    const { dimension } = this.link.image;
    const container = this.$refs.imageContainer;
    const imageAspectRatio = dimension.width / dimension.height;
    const containerAspectRatio = container.clientWidth / container.clientHeight;

    this.highResolutionImage = this.$toLitiumMediaUrl(this.link.image.id,
      {
        ...(imageAspectRatio < containerAspectRatio
          ? { maxWidth: container.clientWidth }
          : { maxHeight: container.clientHeight }),
      });

    this.$nextTick(() => {
      const img = new Image();

      img.onload = () => {
        this.imgUrl = this.highResolutionImage;
        this.isImageDownloaded = true;
      };

      img.src = this.highResolutionImage;
    });
  },
  computed: {
    linkName() {
      return this.link.name;
    },
    linkUrl() {
      return this.link.url;
    },
    linkSubtitle() {
      return this.link.linkSubtitle;
    },
  },
};
</script>
<template>
  <router-link v-if="linkUrl" :to="linkUrl" class="link-box-slider__container">
    <div class="link-box-slider__image-container" ref="imageContainer">
      <div class="link-box-slider__image-spacer"></div>
      <img v-if="imgUrl" class="link-box-slider__image" :src="imgUrl">
      <div v-if="!imgUrl" class="link-box-slider__image-missing"></div>
    </div>
    <div class="link-box-slider__title">{{linkName}}</div>
    <div class="link-box-slider__subtitle">{{linkSubtitle}}</div>
  </router-link>
</template>
<style>

  .link-box-slider__image-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .link-box-slider__image-spacer {
    padding-top: 135%;
    background: var(--color-neutrals-45);
  }

  .link-box-slider__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .link-box-slider__image-missing {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('../static/icons/image_default.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .link-box-slider__title,
  .link-box-slider__subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  .link-box-slider__title {
    font-weight: 700;
  }

  @media (--phone) {
    .link-box-slider__image-container {
      margin-top: 30px;
    }
  }
</style>
